<template>
  <div class="home">
      <b-card header="Changelog">
        <blockquote class="blockquote mb-0">
          <p id="Pchangelog">
            <ul>
              <li v-for="item in changelog" :key="item.message">
                {{ item.version }}
                <ul>
                  <li>
                    {{ item.opis }}
                  </li>
                </ul>
              </li>
            </ul>
          </p>
        </blockquote>
      </b-card>
  </div>
</template>

<script>
// @ is an alias to /src
//import jsonChangelog from "@/json/changelog.json";

export default {
  name: "Home",
  data: function () {
    return {
      //changelog: jsonChangelog,
      changelog: [
        { version: "0.6", opis: "implementacja vuex" },
        { version: "0.5", opis: "poprawki stylowania" },
        { version: "0.4", opis: "poprawki bledow" },
        { version: "0.3", opis: "dodano favicon, przejscie do rekordu po dodaniu nowego przepisu" },
        { version: "0.2", opis: "dodano changelog, naprawiono transakcje, dodano modal dla obslugi bledow z axios" },
        { version: "0.1", opis: "pierwsza wersja" },
      ],
    };
  },
};
</script>

<style scoped>
#Pchangelog {
  font-size: 16px;
  text-align: left;
  word-wrap: break-word;
}
</style>
