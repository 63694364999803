import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/HomeView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dodaj',
    name: 'AddRecipe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AddRecipe" */ '@/views/AddRecipeView.vue')
  },
  {
    path: '/szukaj/:rname?',
    name: 'Search',
    component: () => import(/* webpackChunkName: "Search" */ '@/views/SearchView.vue'),
    props: true
  },
  {
    path: '/przepis/:id',
    name: 'Recipe',
    component: () => import(/* webpackChunkName: "Recipe" */ '@/components/Recipe.vue'),
    props: true
  },
]

// function castRouteParams(route) {
//   return {
//     idr: Number(route.params.idr),
//   };
// }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
