const auth = {
    state: 
        { 
            name: "test"
        },
    mutations: { 
        },
    actions: {
        WyswietlLogKonsoli: () => {
            console.log("Fajny tekst")
        }
    },
    getters: {  }
  }

  export default auth