import Vue from 'vue'
import Vuex from 'vuex'
import rc from './modules/rc.js'
import auth from './modules/auth.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    test: "Jestem legendą!"
  },
  mutations: {
    SET_TEST(state, status) {
      state.test = status;
    },
  },
  actions: {
  },
  modules: {
    rc,
    auth
  }
})