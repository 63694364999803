<template>
  <div id="app">
    <TheNavBar />

    <div id="app-router-view">
      <br />
      <br />

      <router-view />
    </div>
  </div>
</template>

<script>
import TheNavBar from "./components/TheNavBar.vue";

export default {
  name: "app",
  components: {
    TheNavBar,
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  touch-action: manipulation;
}

#app-router-view {
  width: 95%;
  margin: auto;
  max-width: 1200px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#test2 {
  display: flex;
}
</style>
