// recipe and components data

import axios from "axios"
import router from '@/router'


const rc = {
  namespaced: true,
  state: {
    idr: "",
    searchRecipesArray: [],
    recipeCategoryArray: [],
    recipeFormObject: {
      name: "",
      idc: null,
      eaters: null,
      opis: "",
      components: [] //tutaj dodajemy dane z RecipeComponents
    },
    componentsArray: [],
    componentsMeasureArray: [], // tablica mozliwych miar skladnika
    componentsCategoryArray: [], // tablica dla kategorii skladnika
    loadingStatus: "not loading",
  },
  mutations: {
    SET_RECIPE_IDR(state, data) {
      state.idr = data;
    },
    SET_SEARCH_RECIPES(state, data) {
      state.searchRecipesArray = data;
    },
    SET_RECIPE_FORM(state, data) {
      state.recipeFormObject = data;
    },
    RESET_RECIPE_FORM(state) {
      state.recipeFormObject = {
        name: "",
        idc: null,
        eaters: null,
        opis: "",
        components: []
      }
    },
    SET_CATEGORY_DATA(state, data) {
      state.recipeCategoryArray = data;
    },
    SET_COMPONENTS_MEASURE(state, data) {
      state.componentsMeasureArray = data;
    },
    SET_COMPONENTS_CATEGORY(state, data) {
      state.componentsCategoryArray = data;
    },
    SET_COMPONENTS_DATA(state, data) {
      state.componentsArray = data;
    },
    // SET_LOADING_STATUS(state, status) {
    //   state.loadingStatus = status;
    // },
  },
  actions: {
    getRecordById(context, idr) {
      if (idr !== undefined)
        //sprawdza czy jest id rekordu
        axios
          .get("/./api/recipe2.php", {
            params: {
              idr: idr,
            },
          })
          .then((response) => {
            context.commit('SET_RECIPE_FORM', response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getSearchRecipes(context, rname) {
      //context.commit('SET_LOADING_STATUS','loading')
      axios
        .get('/./api/test.php', {
          params: {
            rname: rname,
          },
        })
        .then((response) => {
          //context.commit('SET_LOADING_STATUS','not loading')
          context.commit('SET_SEARCH_RECIPES', response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getCategory(context) {
      axios
        .get("/./api/category.php")
        .then((response) => {
          //console.log(response.data);
          context.commit('SET_CATEGORY_DATA', response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getComponents(context, id_comp) {
      axios
        .get("/./api/components.php", {
          params: {
            id_comp: id_comp,
          },
        })
        .then((response) => {
          //console.log(response.data);
          context.commit('SET_COMPONENTS_DATA', response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    postRecipes(context, form) {
      console.log(form);
      axios
        .post("/./api/recipe2.php", form)
        .then((response) => {
          console.log(response.status)
          //handle success
          router.push({
            name: "Recipe",
            params: { id: response.data.idr },
          });
        })
        .catch((response) => {
          //handle error
          console.log(response);
          this.$bvModal.show("bv-modal-example");
        });
    },
    getComponentsMeasure(context) {
      axios
        .get("/./api/componentsType.php")
        .then((response) => {
          context.commit('SET_COMPONENTS_MEASURE', response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getComponentsCategory(context) {
      axios
        .get("/./api/componentsCat.php")
        .then((response) => {
          context.commit('SET_COMPONENTS_CATEGORY', response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    postComponents(context, obj) {
      axios
        .post("/./api/components.php", {
          name: obj.componentsName,
          id_comp_type: obj.componentsMeasureID,
          id_comp_cat: obj.componentsCategoryID,
        })
        .then((response) => {
          console.log(response.status);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
}

export default rc