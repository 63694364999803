<template>
    <div class="navBar">
      <b-navbar toggleable="md" type="light" variant="light">
        <b-navbar-brand to="/">Home</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/dodaj">Dodaj przepis</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-form-input
                size="sm"
                class="mr-sm-2"
                v-model='tbSearch'
                @keydown.enter.prevent="SzukajPrzepisow"
                placeholder="Szukaj"
              ></b-form-input>
              <b-button
                size="sm"
                class="my-2 my-sm-0"
                @click="SzukajPrzepisow"
                >Szukaj</b-button
              >
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "TheNavBar",
  data: function () {
    return {
      tbSearch: undefined
    };
  },
  // watch: {
  //     tbSearch: function(val) {
  //         //do something when the data changes.
  //         if (val === undefined) {
  //           this.tbSearch = '';
  //         }
  //     }
  // },
  methods: {
    SzukajPrzepisow: function () {
    (!this.tbSearch ? this.$router.push({ name: 'Search'}) : this.$router.push({ name: 'Search', params: { rname: this.tbSearch }}).catch(error => console.error(error)))
    //this.$router.push({ name: 'List', params: { rname: this.tbSearch }})
    }
  }
}
</script>
